<template>
    <div class="dashboard">
        <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
            <span>{{ message }}</span>
        </v-snackbar>
        <v-img lazy-src="img/smoking.jpg" src="img/smoking.jpg" aspect-ratio="0.9" height="250"
            style="margin-top: -110px"></v-img>
        <v-container>
            <div class="pa-2 mt-1">
                <v-layout column>
                    <v-flex xs12>
                        <b>
                            Last Updated: 2022-04-20
                        </b>
                        <p>
                            Welcome to DISTRXCT and thank you for visiting. This website, as well as any other media
                            form,
                            media channel, mobile application, or mobile website related or connected thereto
                            (collectively,
                            the “Site") is owned and operated by DISTRXCT (“Company" or “we"). The Site contains
                            original
                            content, text, images, designs, audiovisual materials, software, information, data,
                            advertising,
                            brands and logos that incorporate intellectual property in which we own exclusive rights, or
                            have the right to use (collectively, the “Content"). Your use of the Site and the Content,
                            as
                            well as any products (“Products”) or services supplied by us or on our behalf, including our
                            text services or services provided through the Site (collectively, “Services”) is subject to
                            these terms and conditions (the “Terms of Use"), which form a binding and enforceable
                            agreement
                            between you and us (the “Agreement"). These Terms of Use are intended to protect our rights
                            so
                            that we can provide you access to the Content on this Site. By remaining on the Site or
                            returning to it, you agree to be bound by these Terms of Use.
                            When you interact with us through the Site by sending us an email or other electronic
                            communication, we interpret, and you agree, that such communication satisfies any
                            requirement
                            under the law that such communication is a legal writing. You further affirm that you are
                            fully
                            able and competent to enter into the terms, conditions, obligations, affirmations,
                            representations, and warranties set forth in these Terms of Use, and to abide by and comply
                            with
                            these Terms of Use. These Terms of Use apply to all users of the Site, including without
                            limitation users who are browsers, vendors, customers, merchants, and/or contributors of
                            content.
                        </p>
                        <p>
                            USERS WITH QUESTIONS REGARDING THE LEGALITY OF CANNABIS AGREE THEY HAVE CONTACTED
                            INDEPENDENT
                            LEGAL COUNSEL OF THEIR CHOOSING OR HAVE KNOWINGLY CHOSEN TO WAIVE THAT RIGHT BEFORE USING
                            THIS
                            SITE OR SERVICES.
                            We may update this Agreement from time to time without notice. Supplemental terms and
                            conditions
                            or documents that may be posted on the Site from time to time, and any new features or tools
                            that are added to the Site shall also be subject to and are hereby expressly incorporated
                            into
                            these Terms of Use by reference. Any changes in our Terms of Use will be incorporated into a
                            revised Agreement that we will post on the Site. All visitors have the obligation to review
                            changes to this Agreement, including any changes to our Privacy Policy. Unless otherwise
                            specified, such changes shall be effective when they are posted. Your access or use of our
                            Site
                            constitutes your agreement to be bound by this Agreement, including any changes that exist
                            when
                            you re-access our Site.
                        </p>
                        <p>
                            IF YOU DO NOT AGREE WITH THE TERMS OF THIS AGREEMENT, OR ANY POSTED GUIDELINES OR RULES,
                            THEN
                            PLEASE LEAVE THIS SITE IMMEDIATELY.
                        </p>
                        <p>
                            <b>1. AGE VERIFICATION</b>
                        </p>
                        <p>
                            To visit, participate in any activities and/or place an order on our Site you must be at
                            least
                            21 years of age (or 18 years of age with a valid Medical Marijuana Identification Card or
                            physician’s recommendation). Individuals under the age of 18 (or 21 for applicable Services)
                            are
                            prohibited from creating a Member Account, accessing the Site or Services, and ordering
                            Products.
                        </p>
                        <p>
                            <b>2. PRODUCT DISCLAIMER AND SAFETY ACKNOWLEDGMENT</b>
                        </p>
                        <p>
                            On our Site, we may provide information about Products. Our Site may collect information
                            about a
                            user’s experiences and preferences related to the consumption of cannabis products. Cannabis
                            is
                            a Schedule 1 controlled substance under the Controlled Substances Act, and, therefore, the
                            possession, cultivation, and distribution thereof, or conspiring with or assisting other to
                            do
                            the same, is federally illegal and can result in significant criminal and civil penalties.
                            You
                            further acknowledge that medical cannabis use is not recognized as a valid defense under
                            federal
                            laws regulating cannabis, and that the interstate transportation of cannabis is a federal
                            offense. ENGAGING IN ACTIVITIES OR BUSINESS RELATED TO CANNABIS IS AT YOUR OWN RISK.
                        </p>
                        <p>
                            By using the Site, you acknowledge that the information contained in these Terms of Use, the
                            information provided on this Site, within any of the Company’s social media pages or
                            channels,
                            and all of the documentation and literature included with any Product identified on the
                            Site,
                            was developed for informational and educational purposes only. In no way is any of the
                            information contained in these Terms of Use or elsewhere on the Site intended to be a
                            medical or
                            prescriptive guide or a substitute for informed medical advice or care, nor is it intended
                            to
                            cover all possible uses, directions, precautions, or adverse effects. The statements made
                            regarding the Products on our Site have not been evaluated by the Food and Drug
                            Administration
                            (the “FDA"). The efficacy of these Products has not been confirmed by FDA-approved research.
                            These Products are not intended to diagnose, treat, cure or prevent any disease. All
                            information
                            presented here is not meant as a substitute for or alternative to information from health
                            care
                            practitioners. Please consult your health care professional about potential interactions or
                            other possible complications before using any Product. The Federal Food, Drug and Cosmetic
                            Act
                            requires this notice.
                        </p>
                        <p>
                            You should never delay seeking or disregard advice from a medical professional based on
                            something you have read on the Site. Do not operate vehicles or machinery while under the
                            influence of cannabis. If you are pregnant or may be pregnant, breastfeeding, suffering from
                            a
                            known health condition, or taking any prescription medications, you should consult a
                            physician
                            or other health care professional prior to using cannabis. You and any other user of any
                            Product
                            on the Site are solely responsible for the use of such Product and the consequences of such
                            use.
                            Any illegal use or resale of any Products listed on the Site could subject you to fines,
                            penalties, and/or imprisonment under state and federal law.
                        </p>
                        <p>
                            <b>3. RESTRICTED LOCATIONS</b>
                        </p>
                        <p>
                            We do not sell Products which qualify as THC-regulated cannabis material outside of
                            jurisdictions where such activities are legally allowed. We comply with all regulations
                            required
                            by law applicable to our Products. We do not ship THC-containing cannabis Products. Please
                            visit
                            a licensed cannabis retailer to purchase THC-containing Products. Please check back
                            frequently
                            as these restrictions are subject to change.
                        </p>
                        <p>
                            Information provided on the Site is not intended for distribution to or use by any person or
                            entity in any jurisdiction or country where such distribution or use would be contrary to
                            law or
                            regulation or which would subject us to any registration requirement within such
                            jurisdiction or
                            country. Persons who choose to access the Site from such locations do so on their own
                            initiative
                            and are solely responsible for compliance with local laws, if and to the extent local laws
                            are
                            applicable.
                        </p>
                        <p>
                            <b>4. INTELLECTUAL PROPERTY</b>
                        </p>
                        <p>
                            We or our licensors or partners own the intellectual property rights in the Content and
                            materials displayed on the Site. These materials are protected by copyrights, trademarks,
                            service marks, patents, trade secrets or other proprietary rights and laws, and we own or
                            control all materials or a third party that submitted materials to the Site owns the
                            material.
                            Except as expressly authorized by the Site, you may not modify, copy, reproduce, republish,
                            upload, post, transmit, distribute, sell, license, rent, publicly display or perform, edit,
                            adapt or create a derivative work of any material, Content or design elements obtained from
                            the
                            Site, including code and software (“Material”).
                        </p>
                        <p>
                            You may use the Site (including such Content and Material) for not-for-profit,
                            non-commercial
                            use, but you may not use it for commercial purposes. We reserve the right to revoke your
                            right
                            to use the Site Content and Material for any reason upon notice. If you receive such a
                            notice
                            from us, you agree to discontinue such use of the Site.
                        </p>
                        <p>
                            No right, title, or interest in any materials or software is transferred to you as a result
                            of
                            use of this Site.
                        </p>
                        <p>
                            TRADEMARKS. DISTRXCT, BREADZ, and other select depicted brands are trademarks to which
                            DISTRXCT
                            Creative Consulting & Productions LLC ("DISTRXCT" hereafter) has rights in the U.S., whether
                            or
                            not federal registration has been pursued ("The DISTRXCT Trademarks"). The DISTRXCT
                            Trademarks
                            and any related trade dress may not be used without written permission from a duly
                            authorized
                            officer of Distrxct, and may not be used in connection with any Product or service that is
                            not a
                            Distrxct endorsed or produced Product or service. All other trademarks not owned by Distrxct
                            that appear on the Site are the property of their respective owners.
                        </p>
                        <p>
                            COPYRIGHTS Certain content on this Site, including but not limited to literary, dramatic,
                            musical, visual, and other artistic works, is protected by U.S. Copyright Law and you agree
                            that
                            you will not reprint, republish, or distribute any portion of the Content without expressed
                            written permission to do so.
                        </p>
                        <p>
                            <b>5. DMCA POLICY</b>
                        </p>
                        <p>
                            The Digital Millennium Copyright Act of 1998 (the "DMCA") provides recourse for copyright
                            owners
                            who believe that material appearing on the Internet infringes their rights under U.S.
                            copyright
                            law.
                        </p>
                        <p>
                            This Site respects the intellectual property rights of others. If you believe in good faith
                            that
                            materials hosted by the Site infringe your copyright (for example, materials posted by a
                            user in
                            connection with a review), you (or your agent) may send us a notice requesting that the
                            material
                            be removed or access to it blocked. If you believe that your copyright-protected work has
                            been
                            copied and posted on the Site in a way that constitutes copyright infringement, then please
                            contact our DMCA Designated Agent (as identified below), with the following information:
                        </p>
                        <p style="margin-left: 40px;">
                            1. An electronic signature of the person authorized to act on behalf of the owner of the
                            copyright or other intellectual property interest;
                        </p>
                        <p style="margin-left: 40px;">
                            2. Reasonably sufficient details to enable us to identify the work claimed to be infringed
                            or,
                            if multiple works are claimed to be infringed, a representative list of such works (for
                            example:
                            title, author, any registration or tracking number, URL);
                        </p>
                        <p style="margin-left: 40px;">
                            3. Reasonably sufficient detail to enable us to identify and locate the material that is
                            claimed
                            to be infringing (for example a link to the page that contains the material);
                        </p>
                        <p style="margin-left: 40px;">
                            4. Your address, telephone number, and email address;
                        </p>
                        <p style="margin-left: 40px;">
                            5. A statement that the you have a good faith belief that the disputed use is not authorized
                            or
                            consented to by the copyright or intellectual property owner, its agent, or the law; and
                        </p>
                        <p style="margin-left: 40px;">
                            6. A statement, made under penalty of perjury, that the above information in the notice is
                            completely accurate and that you are the copyright or intellectual property owner or are
                            authorized to act on the copyright or intellectual property owner's behalf.
                        </p>
                        <p>
                            It is our policy that, upon receiving of a valid DMCA notice, we will remove or disable
                            access
                            to allegedly infringing material. There are substantial penalties for false claims (see 17
                            U.S.C. § 512(f) - providing sanctions for material misrepresentations of copyright
                            infringement).
                        </p>
                        <p>
                            Pursuant to Title II of the DMCA, all claims alleging copyright infringement for material
                            that
                            you believe to be residing on our Site, system or network should be promptly sent in the
                            form of
                            written notice to our designated agent:
                        </p>
                        <p>
                            DESIGNATED AGENT FOR DMCA NOTICES AND PROCESS (THE "DMCA AGENT"): Distrxct Creative
                            Consulting &
                            Productions, LLC Attn: Distrxct 1629 K ST. Suite 300 Washington D.C., 20006 EMAIL:
                            info@distrxct.com
                        </p>
                        <p>
                            You may not send other notices or communications to the DMCA Agent, who is appointed solely
                            for
                            the purpose of receiving notices of claims alleging copyright infringement under the DMCA.
                            FILING A DMCA COUNTER-NOTIFICATION. If you believe a notice of alleged copyright
                            infringement
                            under the DMCA has been wrongly filed against you, you may submit a counter-notification to
                            our
                            DMCA Agent. Specific requirements for a proper counter-notification are set forth in the
                            DMCA
                            (see 17 U.S.C. § 512(g)(3)). A valid counter-notification must be a written communication
                            that
                            includes all of the following elements:
                        </p>
                        <p style="margin-left: 40px;">
                            1. A physical or electronic signature;
                        </p>
                        <p style="margin-left: 40px;">
                            2. Identification of the material that has been removed or to which access has been disabled
                            and
                            the location at which the material appeared before it was removed or access to it was
                            disabled;
                        </p>
                        <p style="margin-left: 40px;">
                            3. A statement under penalty of perjury that you have a good faith belief that the material
                            was
                            removed or disabled as a result of mistake or misidentification; and
                        </p>
                        <p style="margin-left: 40px;">
                            4. Your name, address, and telephone number, and a statement that you consent to the
                            jurisdiction of federal district court for the judicial district in which the address is
                            located, or if your address is outside of the U.S., for any judicial district in which the
                            service provider may be found, and that you will accept service of process from the
                            complainant.
                        </p>
                        <p>
                            Upon receipt of a valid counter-notification, we will forward it to the original complainant
                            who
                            submitted the DMCA notice alleging copyright infringement. The original complainant will
                            then
                            have ten days to notify us that it has filed a lawsuit relating to the allegedly infringing
                            material otherwise we will restore the removed material or cease disabling access to it.
                        </p>
                        <p>
                            REPEAT INFRINGER POLICY. Pursuant to Section 512 of the DMCA, it is our policy to terminate
                            the
                            Member Account or other account/access of any repeat copyright infringer in appropriate
                            circumstances.
                        </p>
                        <p>
                            <b>6. USE OF THE SITE</b>
                        </p>
                        <p>
                            You may not use the Site in any way that is objectionable, unlawful, or impairs the
                            functioning
                            or use of the Site by us or other users. If, in our sole discretion, you violate these Terms
                            of
                            Use, we may suspend, deny or restrict your access to the Site or any Content. Our decision
                            whether to suspend, deny or restrict access in no way limits any other right or remedy that
                            we
                            may have against you. We may take any other action that we deem appropriate to protect
                            ourselves
                            and our users.
                        </p>
                        <p>
                            More specifically, under these Terms of Use, you agree to refrain from, among other things,
                            use
                            of the Site or the Content in a manner that:
                        </p>
                        <p style="margin-left: 40px;">
                            1. is threatening, harassing, abusive, defamatory, slanderous, fraudulent, gratuitously
                            violent,
                            obscene, deceptive, pornographic, libelous, harmful to minors, invasive of another's
                            privacy, or
                            racially, ethnically or otherwise offensive, hateful or abusive;
                        </p>
                        <p style="margin-left: 40px;">
                            2. infringes or misappropriates our, or a third party's, intellectual property,
                            confidentiality,
                            or other rights;
                        </p>
                        <p style="margin-left: 40px;">
                            3. violates export or re-export control laws and regulations;
                        </p>
                        <p style="margin-left: 40px;">
                            4. advocates or solicits violence, criminal conduct or the violation of law or the rights of
                            any
                            third party;
                        </p>
                        <p style="margin-left: 40px;">
                            5. distributes advertising or promotional content;
                        </p>
                        <p style="margin-left: 40px;">
                            6. compiles, uses, downloads or otherwise copies any user information or usage information
                            (whether or not such information constitutes personally identifiable information), or that
                            impermissibly transmits, provides or otherwise distributes such information to any third
                            party;
                        </p>
                        <p style="margin-left: 40px;">
                            7. provides material support, or resources (or conceals or disguises the nature, location,
                            source, or ownership of material support or resources), to any organization designated by
                            the
                            United States Federal Government as a foreign terrorist organization pursuant to Section 219
                            of
                            the Immigration and Nationality Act;
                        </p>
                        <p style="margin-left: 40px;">
                            8. decompiles, disassembles, uses reverse engineering or otherwise attempts to derive the
                            source
                            code for the computer systems and other technology that operate the Site or that is
                            available
                            through the Site;
                        </p>
                        <p style="margin-left: 40px;">
                            9. accesses (or attempts to access) the Site or any of the Content by any means other than
                            through the means that we provide, including without limitation by any automated script or
                            routine, including "robots," "spiders," "offline readers," bots, web crawlers or other means
                            that accesses the Site or the Content in a manner that sends more request messages to
                            servers in
                            a given period of time than a human can reasonably produce in the same period by using a
                            conventional online web browser;
                        </p>
                        <p style="margin-left: 40px;">
                            10. interferes with or disrupts the Site (or the servers and networks which are connected to
                            the
                            Site), whether via malicious software or otherwise; or
                        </p>
                        <p style="margin-left: 40px;">
                            11. reproduces, duplicates, redisplays, frames, makes copies of, or resells the Site, or any
                            of
                            the Content for any purpose without our express, written permission.
                            You may not use this Site to transmit unsolicited email. You may not send unsolicited email
                            to
                            this Site or to anyone whose email address includes the domain name used on this Site. You
                            may
                            not use our domain name as a pseudonymous return email address for any communications which
                            you
                            transmit from another location or through another service. You may not pretend to be someone
                            else—or spoof their identity—when using this site.
                        </p>
                        <p>
                            You may neither actually nor attempt (nor otherwise authorize, encourage, or support others)
                            to
                            circumvent, re-engineer, decrypt, break, or otherwise alter or interfere with the Site or
                            its
                            operations.
                            Use of any Content or Material for any purpose not expressly permitted in this Agreement is
                            prohibited. For information on requesting permission to use, reproduce or otherwise
                            distribute
                            any of the Site Content or Material for commercial purposes, please send a written
                            electronic
                            request to info@distrxct.com Decisions to grant or deny permission are within our sole
                            discretion.
                        </p>
                        <p>
                            <b>7. SITE CONTENT DISCLAIMER</b>
                        </p>
                        <p>
                            Our Site may also contain facts, views, opinions and statements of third parties, visitors
                            and
                            other organizations. The Site, its parents, affiliates and subsidiaries do not represent or
                            endorse the accuracy or reliability of any advice, opinion, statement or other information
                            displayed or distributed through our Site. You acknowledge that any reliance upon any such
                            advice, opinions, statement or other information shall be at your sole risk and you agree
                            that
                            the Site, its parents, affiliates and subsidiaries shall not be held responsible or liable,
                            directly or indirectly, for any loss or damage caused or alleged to have been caused in any
                            way
                            whatsoever related to any advice, opinions, statements or other information displayed or
                            distributed on our Site. The Site and its Content do not constitute endorsements or
                            guarantees
                            by the Site of any featured venue or the Products or services available from such venues.
                        </p>
                        <p>
                            Content on the Site is provided to you AS IS for your information and personal use only, and
                            may
                            not be used, copied, reproduced, aggregated, duplicated, distributed, transmitted,
                            broadcast,
                            displayed, sold, resold, licensed, or otherwise exploited for any commercial purpose that is
                            not
                            expressly permitted and authorized with the prior written consent of the respective owners.
                            We
                            are not responsible if Content is not accurate, complete, or current. The Content is
                            provided
                            for general information only and should not be relied upon or used as the sole basis for
                            making
                            decisions without consulting primary, more accurate, more complete, or more timely sources
                            of
                            information. Any reliance on the Content is at your own risk. We reserve the right to modify
                            the
                            Content without notice at any time. We shall not be liable to you or to any third party for
                            any
                            modification, change, suspension, or discontinuance of the Site, Services, or Products.
                        </p>
                        <p>
                            Occasionally there may be information on our Site or in the Services that contains
                            typographical
                            errors, inaccuracies, or omissions that may relate to Product descriptions, pricing,
                            promotions,
                            offers, Product delivery charges, transit times and availability. We reserve the right to
                            correct any errors, inaccuracies or omissions, and to change or update information or cancel
                            orders if any information in the Site or Service or on any related website is inaccurate at
                            any
                            time without prior notice (including after you have submitted an order). We undertake no
                            obligation to update, amend, or clarify information on the Site or related to the provision
                            of
                            any Product, Service, or any related website, including without limitation pricing
                            information,
                            except as required by law. No specified update or refresh date applied in the Site or
                            Service or
                            on any related website should be taken to indicate that all information in the Site or
                            Service
                            or on any related website has been modified or updated.
                        </p>
                        <p>
                            <b>8. YOUR SUBMISSION OF PERSONAL INFORMATION TO THE SITE</b>
                        </p>
                        <p>
                            To participate in activities on our Site, we may request that you submit certain personally
                            identifiable information about yourself, including, for example, your name and personal
                            contact
                            information (“personal information”). We may also gather certain types of non-personally
                            identifiable information about your visit to protect the security of our members or our Site
                            or
                            to make our Content more enjoyable for all our visitors.
                        </p>
                        <p>
                            All information gathered from visitors by the Site will be governed by our Privacy Policy,
                            which
                            is incorporated in this Terms of Use Agreement by reference. If there is a conflict between
                            the
                            terms of this Agreement and our Privacy Policy terms of the Privacy Policy will prevail.
                            Please
                            carefully review our Privacy Policy to understand our collection, use and disclosure
                            practices.
                        </p>
                        <p>
                            <b>9. ELECTRONIC SITE COMMUNICATIONS</b>
                        </p>
                        <p>
                            The communications between you and us via this Site use electronic means, whether you visit
                            this
                            Site or send us an email, or whether we post notices on this Site or communicate with you
                            via
                            email. For contractual purposes, once you sign up for email notices with the Site, you
                            consent
                            to receive communications from us in an electronic form, and you agree that all terms and
                            conditions, agreements, notices, disclosures, and other communications that we provide to
                            you
                            electronically satisfy any legal requirement that such communications would satisfy if it
                            were
                            in writing. The foregoing does not affect your non-waivable rights. By providing your email
                            address, you agree that we may send you emails concerning our Site and Services, as well as
                            information related to third parties. You will have the option to opt out of such emails by
                            following instructions to unsubscribe, which will be included in each email.
                        </p>
                        <p>
                            If you provide us with your cell phone number and opt-in to our text service, you agree and
                            consent to receive certain text messages from us regarding the Site, Products, and Services.
                            These text messages may relate to the Site’s operations or include promotional messages.
                            Standard text messaging rates will be applied by your mobile device carrier to any text
                            messages
                            sent by us. You will have the option to opt-out of receiving text messages by replying
                            “STOP” to
                            any text message sent by us. After requesting to opt out, you may receive text messages from
                            us
                            for just a brief time while your request is processed.
                        </p>
                        <p>
                            Company text messages may be generated by automatic telephone dialing systems and you hereby
                            waive your right to pursue any claims (including any claim that arises while your request to
                            opt
                            out is pending) under the Telephone Consumer Protection Act (“TCPA”). To the extent any
                            claim
                            under the TCPA cannot be waived, by using the Site or Services, you are agreeing that any
                            claim
                            against Company that cannot be waived but which arises under the TCPA (including any claim
                            that
                            arises while your request to opt out is pending), will be arbitrated on an individual, and
                            not
                            on a class or representative, basis, in accordance with the Choice of Law and Forum
                            provisions
                            of these Terms of Use.
                        </p>
                        <p>
                            As further detailed in our Privacy Policy, you may unsubscribe from our email list at any
                            time.
                            Directions to unsubscribe are enclosed in each email, with alternate unsubscribe options
                            specified in our Privacy Policy. Please note, there are some circumstances (e.g.,
                            organizational
                            audits) when we may need to contact you even if you unsubscribe. Users also agree to be
                            contacted in this case even if you have unsubscribed.
                        </p>
                        <p>
                            YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS,
                            AND
                            TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR
                            COMPLETED
                            BY US OR VIA THE SITE. You hereby waive any rights or requirements under any statutes,
                            regulations, rules, ordinances, or other laws in any jurisdiction with require an original
                            signature or delivery or retention of non-electronic records, or to payments or the granting
                            of
                            credits by any means other than electronic means.
                        </p>
                        <p>
                            <b>10. REGISTRATION AND MEMBER ACCOUNTS</b>
                        </p>
                        <p>Registration with the Site’s email list may be required in order to access certain services,
                            including, without limitation, promotions, special offers, Product discounts, etc. Your
                            registration information will be handled by us in accordance with our Privacy Policy which
                            you
                            should review prior to registering with us. We encourage you to keep us informed about any
                            changes in your personal contact and email information.</p>
                        <p>In order to register to use certain features of the Site, you may also be required to
                            complete a
                            registration process to obtain a member account (“Member Account”) through our Site or in
                            one of
                            our retail stores. In order to obtain a Member Account, you may be asked to provide your
                            name,
                            phone number, email address, mailing address, date of birth, interests, and certain other
                            information. You will then be given the option to create a profile, in response to which,
                            you
                            may, but are not required to, provide additional information about yourself (“User
                            Information”). You must provide complete and accurate information during the registration
                            process and you have an obligation to update this information if and when it changes.</p>
                        <p>By registering with the Site, you agree to receive periodic electronic correspondence from us
                            regarding your Member Account, the Site, our partners and/or any of our Services. You may
                            opt in
                            to receive additional communications from us or our partners.</p>
                        <p>In creating a Member Account, you will be asked to provide a username and password. You are
                            solely responsible for maintaining the confidentiality of your password. You may not use the
                            account, username, or password of someone else at any time. You are also solely responsible
                            for
                            any and all activities that occur under your registration. You agree to notify us
                            immediately of
                            any unauthorized use of your account, username, or password.
                            You agree that you will not create more than one Member Account. By registering and
                            obtaining a
                            Member Account you affirm you will follow the Terms of Use. Your registration constitutes
                            your
                            consent to enter into agreement with us electronically. We shall not be liable for any loss
                            that
                            you incur as a result of someone else using your account, username, or password, either with
                            or
                            without your knowledge. You may be held liable for any losses incurred by us, our
                            affiliates,
                            officers, directors, employees, consultants, agents, and representatives due to someone
                            else’s
                            use of your account, username, or password. We reserve the right to terminate your
                            registration
                            or to refuse the Services to you, without prior notice to you, at any time and for any
                            reason or
                            no reason.</p>
                        <p>
                            <b>11. USER CONTENT</b>
                        </p>
                        <p>
                            All comments, feedback, suggestions, ideas, submissions, or other information disclosed,
                            submitted, or offered to Distrxct on or by this Site or otherwise disclosed, submitted, or
                            offered in connection with your use of this Site (collectively, “User Content”) whether
                            submitted in writing or electronically, shall be and remain Distrxct’ property. Such
                            disclosure,
                            submission, or offer of any User Content shall constitute an assignment to Distrxct of all
                            worldwide right, title, and interest in all copyright and other intellectual property in the
                            User Content. Thus, Distrxct will own exclusively all such right, title, and interest and
                            shall
                            not be limited in any way in its use, commercial or otherwise, of any User Content. Distrxct
                            is
                            and shall be under no obligation: (a) to maintain any User Content in confidence; (b) to pay
                            user any compensation for any User Content; or (c) to respond to any User Content. However,
                            all
                            actions taken in connection with your User Content will be subject to the limitations set
                            forth
                            in our Privacy Policy.
                        </p>
                        <p>
                            You agree that information provided to us by our visitors may be inaccurate or can change
                            with
                            little or no notice. Distrxct is not responsible for inaccurate information provided by
                            visitors
                            to the Site. We take no responsibility and assume no liability for any User Content posted
                            by
                            you or any third party.
                        </p>
                        <p>
                            You agree that we may publish or otherwise disclose your name in connection with your User
                            Content in our sole and absolute discretion. By posting User Content on the Site, you
                            warrant
                            and represent that you own the rights to the User Content and are authorized to post,
                            distribute, display, perform, transmit, or otherwise distribute User Content. You agree that
                            User Content submitted by you to the Site will not violate any right of any third party,
                            including copyright, trademark, privacy, publicity, or other personal proprietary right(s).
                            You
                            further agree that no User Content submitted by you to the Site will be or contain libelous
                            or
                            otherwise unlawful, abusive, or obscene material, or contain any computer virus or other
                            malware
                            that could in any way affect the operation of the Site, Services, or any related website.
                            You
                            may not use a false e-mail address, pretend to be someone other than yourself, or otherwise
                            mislead us or third parties as to the origin of any User Content. You are and shall remain
                            solely responsible for any User Content you make.
                        </p>
                        <p>
                            Finally, we ask that you not send us your ideas for our business. Here’s why. We are always
                            thinking and creating, and we may have similar ideas of our own. To avoid any disputes
                            between
                            us relating to ideas that you have submitted to us you agree that they shall be treated as
                            User
                            Content under these Terms of Use.
                        </p>
                        <p>
                            <b>12. THIRD PARTY LINKS</b>
                        </p>
                        <p>
                            Our Site may contain links and pointers to other website and resources on the Internet that
                            are
                            controlled by third parties. These links are provided solely as a convenience to you and do
                            not
                            constitute an endorsement by the Site, their parents, affiliates or subsidiaries of any
                            third
                            party resources or content. Links do not imply that the Site sponsors, is affiliated, or
                            associated with, or otherwise recommends, certifies or endorses the third party link or
                            site.
                            Any concerns regarding external links or website should be directed to the respective
                            website
                            administrator or system operator. The Site reserves the right, in its sole discretion, to
                            terminate links with any third parties or other website that they deem inappropriate or
                            inconsistent with the Site. The Site, its parents, affiliates and subsidiaries make no
                            representations about the content, functionality or practices of any third party sites and
                            resources and specifically disclaim any and all warranties, express or implied, with respect
                            thereto.
                        </p>
                        <p>In general, we do not object to links to the Site from third party websites. You may link to
                            the
                            Site using the plain text name of the Site and only link to the home page of the Site. Do
                            not,
                            without our written permission: (a) incorporate any of our Content into your website (e.g.,
                            by
                            in-lining or framing); (b) use any of the Distrxct Trademarks, service marks, taglines,
                            slogans,
                            trade names or any other words or codes identifying Distrxct, The Distrxct Trademarks, or
                            the
                            Site in any “meta tag.”</p>
                        <p>
                            <b>13. LEGAL COMPLIANCE</b>
                        </p>
                        <p>
                            You acknowledge, consent, and agree that we may access, preserve, and disclose the
                            information
                            you have submitted to us, if required to do so by law or in a good faith belief that such
                            access, preservation, or disclosure is permitted by our Privacy Policy or reasonably
                            necessary
                            or appropriate for any of the following reasons: (1) to comply with legal process; (2) to
                            enforce these Terms; (3) to respond to claims that any content violates the rights of third
                            parties; (4) to protect our rights, property, or personal safety, or those of any of our
                            partners, agents and affiliates, our users, and the public; or (5) to address your requests.
                        </p>
                        <p>
                            <b>14. DISCLAIMER OF WARRANTIES</b>
                        </p>
                        <p>
                            THE INFORMATION ON THE SITE IS PROVIDED “AS IS.” THE SITE DOES NOT WARRANT, EITHER EXPRESSLY
                            OR
                            BY IMPLICATION, THE ACCURACY OF ANY MATERIALS OR INFORMATION PROVIDED ON THE SITE OR THEIR
                            SUITABILITY FOR ANY PARTICULAR PURPOSE, AND EXPRESSLY DISCLAIM ALL WARRANTIES, INCLUDING,
                            BUT
                            NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
                            ALTHOUGH THE INFORMATION PROVIDED TO VISITORS ON THE SITE IS OBTAINED OR COMPILED FROM
                            SOURCES
                            WE BELIEVE TO BE RELIABLE, THE SITE CANNOT AND DOES NOT GUARANTEE THE ACCURACY, VALIDITY,
                            TIMELINESS OR COMPLETENESS OF ANY INFORMATION OR DATA MADE AVAILABLE TO VISITORS OR ITS
                            SUITABILITY FOR ANY PARTICULAR PURPOSE. NEITHER THE SITE, NOR ANY OF THEIR PARENTS,
                            PARTNERS,
                            AFFILIATES, SUBSIDIARIES, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, OPERATIONAL OR
                            PROMOTIONAL PROVIDERS, PROGRAM PRODUCERS OR SPONSORS ARE LIABLE OR SHALL HAVE RESPONSIBILITY
                            OF
                            ANY KIND TO ANY VISITOR FOR ANY LOSS OR DAMAGE THAT YOU INCUR IN THE EVENT OF: (I) ANY
                            FAILURE
                            OR INTERRUPTION OF THIS SITE; (II) ANY ACT OR OMISSION OF ANY THIRD PARTY INVOLVED IN MAKING
                            THE
                            SITE OR THE DATA CONTAINED HEREIN AVAILABLE TO YOU; (III) ANY OTHER CAUSE RELATING TO YOUR
                            ACCESS OR USE, OR INABILITY TO ACCESS OR USE, ANY PORTION OF THE SITE OR MATERIALS ON THE
                            SITE;
                            (IV) YOUR INTERACTION OR SUBMISSIONS ON THE SITE, INCLUDING, BUT NOT LIMITED TO, RESUMÉ OR
                            EMPLOYMENT SUBMISSIONS OR DIALOGUE BETWEEN HOSTS; OR (V) FROM YOUR FAILURE TO COMPLY WITH
                            THIS
                            AGREEMENT, WHETHER OR NOT THE CIRCUMSTANCES GIVING RISE TO SUCH CAUSE MAY HAVE BEEN WITHIN
                            THE
                            CONTROL OF THE SITE OR OF ANY VENDOR PROVIDING SOFTWARE, SERVICE OR SUPPORT. IN NO EVENT
                            WILL
                            THE SITE, ITS PARENTS, PARTNERS, AFFILIATES, SUBSIDIARIES, MEMBERS, OFFICERS OR EMPLOYEES BE
                            LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT, CONSEQUENTIAL OR INCIDENTAL DAMAGES OR ANY OTHER
                            LOSS
                            OR DAMAGES OF ANY KIND EVEN IF THE SITE, ITS AFFILIATES OR ANY OTHER PARTY HAS BEEN ADVISED
                            OF
                            THE POSSIBILITY THEREOF. PLEASE BE ADVISED THAT ONCE YOU LEAVE THE SITE, YOUR USE OF THE
                            INTERNET WILL BE GOVERNED BY THE TERMS OF USE AGREEMENTS AND PRIVACY POLICIES, IF ANY, OF
                            THE
                            PARTICULAR SITE THAT YOU ARE ACCESSING, INCLUDING THOSE OF OUR OPERATIONAL PROVIDERS,
                            ADVERTISERS, SPONSORS AND PROMOTIONAL PARTNERS. THE SITE, ITS PARENTS, PARTNERS, AFFILIATES,
                            SUBSIDIARIES, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES AND AGENTS WILL NOT BE RESPONSIBLE OR
                            LIABLE FOR THE CONTENT, ACTIVITIES OR PRIVACY PRACTICES OF THE OTHER SITE, OR ANY LOSS OR
                            DAMAGE
                            THAT COULD RESULT FROM LEAVING THE SITE.
                        </p>
                        <p>
                            NEITHER COMPANY NOR ITS AFFILIATED OR RELATED ENTITIES OR ITS VENDORS OR CONTENT PROVIDERS
                            SHALL
                            BE LIABLE TO ANY PERSON OR ENTITY FOR ANY DIRECT OR INDIRECT LOSS, DAMAGE, INJURY, CLAIM, OR
                            LIABILITY OF ANY KIND BASED UPON OR RESULTING FROM YOUR ACCESS TO OR USE OF THE SERVICES OR
                            ANY
                            PRODUCTS, INCLUDING ANY PURCHASES MADE.
                        </p>
                        <p>
                            We reserve the right to limit the quantities of any Products or Services that we offer. We
                            reserve the right to discontinue any Product at any time. Any offer for any Product or
                            Service
                            made on this Site is void where prohibited. WE DO NOT WARRANT THAT THE QUALITY OF ANY
                            PRODUCTS
                            OR SERVICES PURCHASED OR OBTAINED BY YOU WILL MEET YOUR EXPECTATION, OR THAT ANY ERRORS IN
                            THE
                            SERVICE WILL BE CORRECTED.
                        </p>
                        <p>
                            Notwithstanding anything to the contrary contained herein, Company and its affiliates’
                            liability
                            to you for any cause whatsoever and regardless of the form of the action, will at all times
                            be
                            limited to the greater of (a) the amount paid, if any, by you to Company for the Products or
                            Services during the period of one (1) month prior to any cause of action arising, and (b)
                            five
                            dollars ($5).
                        </p>
                        <p>
                            <b>15.VIOLATIONS AND INDEMNIFICATION</b>
                        </p>
                        <p>
                            Your access to, and use of, the Site are subject to these Terms of Use. If you violate any
                            of
                            these Terms of Use, you agree that we may deny you access to the Site. You agree to
                            indemnify,
                            defend and hold harmless us, our affiliates and our licensors, as well as the respective
                            officers, directors, affiliates, employees, or agents of us or any such entities, from and
                            against all losses, expenses, damages and costs, including reasonable attorneys' and
                            experts'
                            fees, arising from or related to claims made by any third party due to or arising out of (a)
                            any
                            content you (or any parties who use your computer, with or without your permission) submit,
                            post
                            or upload to or transmit through the Site, (b) your use of the Site (or use of the Site by
                            any
                            parties who use your computer, with or without your permission), (c) your violation of these
                            Terms of Use or your violation of any laws or regulations or the rights of another through
                            the
                            use of the Site (or such violations by any parties who use your computer, with or without
                            your
                            permission). These indemnity obligations will survive termination of your relationship with
                            us
                            or your cessation of use of the Site. We reserve the right, at your expense, to assume the
                            exclusive defense and control of any matter subject to indemnification by you, in which
                            event
                            you will cooperate with us in asserting any available defenses. If asked to do so, you agree
                            that you will not attempt to access this Site. Your obligation of indemnification herein
                            shall
                            survive termination or expiration of these Terms of Use.
                        </p>
                        <p>
                            NOTICE FOR CALIFORNIA USERS.Under California Civil Code Section 1789.3, California Websites
                            users are entitled to the following specific consumer rights notice: The Complaint
                            Assistance
                            Unit of the Division of Consumer Services of the California Department of Consumer Affairs
                            may
                            be contacted in writing at 400 R Street, Suite 1080, Sacramento, California, 95814, or by
                            telephone at (916) 445-1254 or (800) 952-5210.
                        </p>
                        <p>
                            <b>16.CHOICE OF LAW AND FORUM</b>
                        </p>
                        <p>
                            The Site originates from and is located in the United States, and this Agreement shall be
                            governed by and construed in accordance with the laws of the DISTRICT OF COLUMBIA, excluding
                            its
                            conflicts of law rules. Any dispute arising out of or relating to this Agreement or your
                            access
                            or use of the Site will be subject to the exclusive jurisdiction of the courts located
                            within
                            the DISTRICT OF COLUMBIA and you hereby submit to the personal jurisdiction of such courts.
                            If
                            any provision in this Agreement is held invalid or unenforceable, that provision shall be
                            construed in a manner consistent with applicable law to reflect the original intent of the
                            provision, and the remaining provisions of this Agreement shall remain in full force and
                            effect.
                            Nothing in these Terms of Use will prevent Company from seeking injunctive or other
                            equitable
                            relief, payment of amounts due, or enforcement of an award before any court having
                            jurisdiction
                            over any person or otherwise over such subject matter.
                        </p>
                        <p>
                            <b>17. DISPUTE RESOLUTION</b>
                        </p>
                        <p>
                            This Agreement shall be construed and interpreted in accordance with the laws of the
                            DISTRICT OF
                            COLUMBIA , without giving effect to the choice-of-law rules of that State. Any claim,
                            controversy or dispute arising out of or relating to this Agreement will be exclusively
                            governed
                            by DISTRICT OF COLUMBIA law consistent with the DISTRICT OF COLUMBIA Initiative 71.
                        </p>
                        <p>
                            You agree to attempt in good faith to settle any controversy that may arise between us. In
                            the
                            event we are unable to settle a controversy, you agree to submit in good faith to mediation
                            of
                            the dispute. Any controversy or claim arising out of or relating to this Agreement or the
                            breach
                            thereof that remains unsettled by the Parties through mediation, shall be settled by
                            arbitration
                            in the District of Columbia in accordance with the rules of JAMS then in effect, and
                            judgement
                            upon the award rendered by the arbitrator(s) shall be final and binding upon the parties
                            hereto.
                            You and the Company shall bear their own legal costs incurred in the settlement of any
                            controversy or claim. The arbitration will be kept confidential among you, the Company,
                            JAMS,
                            and the arbitrator(s), except as required in connection with any enforcement of such award
                            or
                            otherwise required by law.
                        </p>
                        <p>
                            YOU HEREBY WAIVE TRIAL BY JURY IN ANY ACTION OR PROCEEDING INVOLVING, DIRECTLY OR
                            INDIRECTLY,
                            ANY MATTER IN ANY WAY ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT.
                        </p>
                        <p>
                            ANY DISPUTE RESOLUTION PROCEEDINGS, WHETHER IN ARBITRATION OR COURT, WILL BE CONDUCTED ONLY
                            ON
                            AN INDIVIDUAL BASIS AND NOT IN A CLASS OR REPRESENTATIVE ACTION OR AS A NAMED OR UNNAMED
                            MEMBER
                            IN A CLASS, CONSOLIDATED, REPRSENTATIVE, OR PRIVATE ATTORNEY GENERAL LEGAL ACTION. YOUR
                            ACCESS
                            AND CONTINUED USE OF THE SITE, PRODUCTS, OR SERVICES SIGNIFIES YOUR EXPLICIT CONSENT TO THIS
                            WAIVER.
                        </p>
                        <p>
                            You consent to personal and subject matter jurisdiction in the Distrxct of Columbia and
                            agree to
                            accept personal service of process relating to any such disputes. You agree you will not
                            initiate or seek to transfer in any way an action relating to or arising out of the
                            Agreement to
                            any other forum.
                        </p>
                        <p>
                            <b>18. SECURITY MEASURES</b>
                        </p>
                        <p>
                            Please review our Privacy Policy for information on our website security measures and the
                            protection of your personal information.
                        </p>
                        <p>
                            <b>19. MISCELLANEOUS</b>
                        </p>
                        <p>
                            These Terms of Use constitute the entire agreement between us relating to your use of the
                            Site
                            and the subject matter addressed herein. The section titles used in these Terms of Use are
                            for
                            convenience only and have no legal or contractual effect. This Site is located and operated
                            in
                            the United States. We make no claims that the Content, materials displayed, distributed,
                            made
                            available or accessed through the Site, are appropriate or may be downloaded outside of the
                            United States. Access to the Site and/or any Content or such Materials may not be legal by
                            certain persons or in certain countries. If you access the Site from outside of the United
                            States you do so at your own risk and are responsible for compliance with the laws of your
                            jurisdiction.
                        </p>
                        <p>
                            If any provision contained herein is found to be invalid by any court having competent
                            jurisdiction, the invalidity of such provision shall not affect the validity of the
                            remaining
                            provisions set forth herein, which shall remain in full force and effect. No waiver of any
                            provision of these Terms of Use shall be deemed a further or continuing waiver of such term
                            or
                            any other term.
                        </p>
                        <p>
                            There is no joint venture, partnership, employment, or agency relationship created between
                            you
                            and Company as a result of these Terms of Use or your use of the Site or Services. Upon
                            Company’s request you agree to furnish Company any documentation, substantiation, or
                            releases
                            necessary to verify or effectuate your compliance with these Terms of Use.
                        </p>
                        <p>
                            <b>20. INITIATIVE 71</b>
                        </p>
                        <p>
                            <b>21 and older</b>
                        </p>
                        <p>
                            An individual 21 years of age or older will be able to lawfully:
                        </p>
                        <p style="margin-left: 40px;">
                            • Possess two ounces or less of marijuana;
                        </p>
                        <p style="margin-left: 40px;">
                            • Use marijuana on private property;
                        </p>
                        <p style="margin-left: 40px;">
                            • Transfer one ounce or less of marijuana to another person, as long as:
                        </p>
                        <p style="margin-left: 80px;">
                            (1) no money, goods or services are exchanged; and
                        </p>
                        <p style="margin-left: 80px;">
                            (2) the recipient is 21 years of age or older;
                        </p>
                        <p style="margin-left: 40px;">
                            • Cultivate within his or her primary residence up to six marijuana plants, no more than
                            three
                            of which are mature.
                            However, even with the enactment of Initiative 71, it remains a crime for
                            anyone to:
                        </p>
                        <p style="margin-left: 40px;">
                            • Possess more than two ounces of marijuana;
                        </p>
                        <p style="margin-left: 40px;">
                            • Smoke or otherwise consume marijuana on public space or anywhere to which the public is
                            invited; including restaurants, bars, and coffee shops;
                        </p>
                        <p style="margin-left: 40px;">
                            • Sell any amount of marijuana to another person; or
                        </p>
                        <p style="margin-left: 40px;">
                            • Operate a vehicle or boat under the influence of marijuana.
                            Anyone under 21 years of age is still prohibited from possessing any marijuana
                        </p>
                        <p>
                            <b>21. CONTACT</b>
                        </p>
                        <p>
                            If you have questions about these Terms of Use, you may contact us at info@distrxct.com
                        </p>
                    </v-flex>
                </v-layout>
            </div>
        </v-container>

        <AuthPopUp v-model="loginCardState" />
    </div>
</template>
<script>
import apiCall from "@/utils/api";
import AuthPopUp from "@/components/auth/AuthPopUp";
import { mapGetters, mapActions } from "vuex";
export default {
    components: {
        AuthPopUp,
    },
    data() {
        return {
            path: process.env.VUE_APP_API_URL,
            loading: false,
            valid: true,
            message: "",
            color: "",
            delete: false,
            confirm: false,
            dialog: false,
            snackbar: false,
        };
    },
    mounted() {
        window.onscroll = () => {
            this.changeColor();
        };
    },
    created() {
        this.closeNav()
        window.scrollTo(0, 0);
        this.changeHeaderColor("transparent");
        this.changeTitleColor("white--text");
        this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
    },
    methods: {
        ...mapActions([
            "closeNav",
            "changeLoginCardState",
            "changeHeaderColor",
            "changeTitleColor",
        ]),
        changeColor() {
            if (
                document.body.scrollTop > 100 ||
                document.documentElement.scrollTop > 100
            ) {
                this.changeHeaderColor("black");
                this.changeTitleColor("white--text");
                this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
            } else {
                this.changeHeaderColor("transparent");
                this.changeTitleColor("white--text");
                this.changeButtonColor(
                    "white--text hidden-sm-and-down text-none caption"
                );
            }
        },
    },
    computed: {
        ...mapGetters([
            "isAuthenticated",
            "loginCardState",
        ]),
    },
};
</script>